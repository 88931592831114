<template>
  <div class="addCrop">
    <van-nav-bar
      :title="navTitle"
      left-text="返回"
      left-arrow
      @click-left="onNavClick"
    />
    <div class="addCrop-wrap">
      <van-form
        validate-trigger="onSubmit"
        @submit="formSubmit"
        :label-width="labelWidth"
        ref="cropAddForm"
      >
        <!-- 作物种类 -->
        <van-field
          name="crop"
          v-model="fieldCropValue"
          label="作物种类"
          placeholder="点击选择作物种类"
          right-icon="search"
          @click-right-icon="openPopup"
        />
        <van-popup v-model="cropShowPopup" position="bottom">
          <van-picker
            show-toolbar
            title="请选择作物种类"
            :columns="cropOptions"
            value-key="name"
            :field-names="cropNames"
            @close="cropShowPopup = false"
            @confirm="cropOnFinish"
          />
        </van-popup>

        <!-- 日历 -->
        <van-field
          label="播种时间"
          v-model="calendarField"
          placeholder="点击选择日期"
          @click="showCalendar = true"
          :rules="[{ required: true, message: '日期必选' }]"
        />
        <van-calendar
          v-model="showCalendar"
          :title="calendarFieldLabel"
          @confirm="calendarOnConfirm"
          :min-date="minDate"
          :max-date="maxDate"
        />

        <van-field
          v-model="form.area"
          name="area"
          label="亩数"
          placeholder="亩数"
          :rules="[{ required: true, message: '亩数必选' }]"
        />
        <div style="margin: 16px">
          <van-button
            round
            block
            :disabled="flag"
            type="info"
            native-type="submit"
            style="background: #001f90"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  Form,
  Field,
  Popup,
  Picker,
  Calendar,
  Button,
  Dialog,
  Notify,
  Toast
} from "vant";
import dayjs from "dayjs";
import {
  getCropTypeListByCropId,
  getLandCultivationCondition,
  getLandCultivationRotation,
  getLandCultivationType,
  postLandCropSave,
  CropList
} from "@/service/serverPage/addCrop.js";

import { postCropReplant } from "@/service/serverPage/cropDetail.js";

export default {
  name: "AddCrop",
  components: {
    "van-nav-bar": NavBar,
    "van-form": Form,
    "van-field": Field,
    "van-popup": Popup,
    "van-picker": Picker,
    "van-calendar": Calendar,
    "van-button": Button
  },
  props: {
    fieldId: String,
    cropId: String
  },
  data() {
    return {
      // form 内容
      form: {
        land_id: "",
        crop_category: "",
        crop_bigcat: "",
        crop_subcat: "",
        crop_id: "",
        crop_type_id: "",
        cultivation_condition: "", // 栽培条件
        cultivation_rotation: "", // 栽培茬口
        cultivation_type: "", // 栽培类型
        plant_time: "", // 日期
        to_harvest_days: "", // 天数
        area: "" // 亩数
      },
      labelWidth: "7em",
      cropShowPopup: false,
      fieldCropValue: "",
      cascaderCropValue: "",
      cropNames: {
        text: "name",
        value: "id"
      },
      cropCascaderIndex: {
        a: "",
        b: "",
        c: ""
      },
      cropOptions: [],
      // 日历
      calendarField: "",
      showCalendarField: false,
      calendarFieldLabel: "",
      showCalendar: false,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 0, 31),
      flag: false
    };
  },
  computed: {
    navTitle() {
      return this.cropId ? "作物改种" : "作物添加";
    }
  },
  methods: {
    openPopup() {
      CropList(this.fieldCropValue).then((res) => {
        this.cropOptions = res.data;
        this.cropShowPopup = true;
      });
    },
    // 返回操作
    onNavClick() {
      this.$router.go(-1);
    },
    // 作物种类接口数据处理
    handleCropData(data) {
      data.map((item) => {
        item.children = [];
      });
      return data;
    },
    // 作物选取成功
    cropOnFinish(value) {
      this.form.crop_category = value.crop_category;
      this.form.crop_bigcat = value.crop_bigcat;
      this.form.crop_subcat = value.crop_subcat;
      this.form.crop_id = value.id;
      this.fieldCropValue = value.name;
      this.cropShowPopup = false;
      this.cropTypeListByCropId(value.id);
      this.landCultivationCondition(value.id, this.fieldId);
      this.landCultivationRotation(value.id, this.fieldId);
      this.landCultivationType(value.id, this.fieldId);
    },
    cropTypeOnConfirm(value) {
      this.cropTypeField = value.name;

      this.form.crop_type_id = value.id;
      console.log(this.form.crop_type_id);
      this.cropTypeShowPicker = false;
    },

    conditionOnConfirm(value) {
      this.conditionField = value.name;

      this.form.cultivation_condition = value.id;

      this.conditionShowPicker = false;
    },

    rotationOnConfirm(value) {
      this.rotationField = value.name;

      this.form.cultivation_rotation = value.id;

      this.rotationShowPicker = false;
    },
    cultivationTypeOnConfirm(value) {
      this.cultivationTypeField = value.name;
      this.form.cultivation_type = value.id;
      this.cultivationTypeShowPicker = false;

      if (value.id === "zb") {
        this.calendarFieldLabel = "播种时间";
        this.dayFieldLabel = "播种至收获天数";
      } else if (value.id === "ymyz") {
        this.calendarFieldLabel = "移栽时间";
        this.dayFieldLabel = "移栽至收获天数";
      } else if (value.id === "dnszp") {
        this.calendarFieldLabel = "萌芽时间";
        this.dayFieldLabel = "萌芽至收获天数";
      }
      // 如果栽培方式有值。。。
      if (this.cultivationTypeField) {
        this.showCalendarField = true;
      }
    },
    calendarOnConfirm(date) {
      this.calendarField = this.form.plant_time =
        dayjs(date).format("YYYY-MM-DD");
      this.showCalendar = false;
    },

    formSubmit() {
      this.form.land_id = this.fieldId;
      Dialog.confirm({
        title: this.navTitle,
        message: "确认进行当前操作？"
      }).then(() => {
        if (this.cropId) {
          // 改种作物
          this.form.oldId = this.cropId;
          postCropReplant(this.form).then((res) => {
            if (res.code === 200) {
              Notify({ type: "success", message: "操作成功" });
              this.$router.push({ path: "/server" });
            } else {
              Notify({ type: "error", message: "操作失败" });
            }
          });
        } else {
          // 新增作物
          postLandCropSave(this.form).then((res) => {
            if (res.code === 200) {
              Notify({ type: "success", message: "操作成功" });
              this.$router.push({ path: "/server" });
            } else {
              Notify({ type: "error", message: "操作失败" });
            }
          });
        }
      });
    },
    // 接口 农作物品种:根据作物种类id获取农作物品种
    cropTypeListByCropId(cropId) {
      getCropTypeListByCropId(cropId).then((res) => {
        if (res.code === 200) {
          if (res.data.length >= 1) {
            this.form.crop_type_id = res.data[0].id;
          }
        }
      });
    },
    // 栽培条件
    landCultivationCondition(cropId, fieldId) {
      getLandCultivationCondition(cropId, fieldId).then((res) => {
        if (res.code === 200) {
          if (res.data.length >= 0) {
            this.flag = false
            this.form.cultivation_condition = res.data[0].id;
          }
        } else {
          Toast(res.message)
          this.flag = true
        }
      });
    },

    // 接口 栽培茬口：根据作物种类id和田地id获取栽培茬口
    landCultivationRotation(cropId, fieldId) {
      getLandCultivationRotation(cropId, fieldId).then((res) => {
        if (res.code === 200) {
          if (res.data.length >= 1) {
            this.flag = false
            this.form.cultivation_rotation = res.data[0].id
          }
        } else {
          Toast(res.message)
          this.flag = true
        }
      });
    },
    // 接口 栽培方式接口：根据作物种类id和田地id获取栽培方式
    landCultivationType(cropId, fieldId) {
      getLandCultivationType(cropId, fieldId).then((res) => {
        if (res.code === 200) {
          if (res.data.length >= 0) {
            this.flag = false
            this.form.cultivation_type = res.data[0].id
          }
        } else {
          Toast(res.message)
          this.flag = true
        }
      });
    }
  }
};
</script>
