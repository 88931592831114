import server from "../axios.config.js";

const baseURL = "http://110.87.103.59:18086/";

// 农作物分类接口
export const getCropCropcategory = function() {
  return server({
    method: "get",
    baseURL: baseURL,
    url: `/commonservice/dict/getList`,
    params: { typeCode: "cropcategory" }
  });
};

// 作物大类、子类接口
export const getCropChild = function(parentId) {
  return server({
    method: "get",
    baseURL: baseURL,
    url: `/commonservice/dict/getList`,
    params: { parentId }
  });
};

// 作物种类接口:通过作物子类获取
export const getCropListBySubcat = function(cropSubcat) {
  return server({
    method: "get",
    baseURL: baseURL,
    url: `/farmfriend/crop/getCropList`,
    params: { crop_subcat: cropSubcat }
  });
};

// 作物品种接口：通过作物种类id获取
export const getCropTypeListByCropId = function(cropId) {
  return server({
    method: "get",
    baseURL: baseURL,
    url: `/farmfriend/cropType/getList`,
    params: {
      crop_id: cropId
    }
  });
};

export const getLandCultivationCondition = function(cropId, fieldId) {
  return server({
    method: "get",
    baseURL: baseURL,
    url: `/farmfriend/crop/getLandCultivationCondition`,
    params: {
      crop_id: cropId,
      land_id: fieldId
    }
  })
}

// 栽培茬口：根据田地的id和作物id获取栽培茬口
export const getLandCultivationRotation = function(cropId, fieldId) {
  return server({
    method: "get",
    baseURL: baseURL,
    url: `/farmfriend/crop/getLandCultivationRotation`,
    params: {
      crop_id: cropId,
      land_id: fieldId
    }
  });
};

// 栽培方式：根据田地的id和作物id获取栽培方式
export const getLandCultivationType = function(cropId, fieldId) {
  return server({
    method: "get",
    baseURL: baseURL,
    url: `/farmfriend/crop/getLandCultivationType`,
    params: {
      crop_id: cropId,
      land_id: fieldId
    }
  });
};

// 田块作物添加
export const postLandCropSave = function(options) {
  return server({
    method: "post",
    baseURL: baseURL,
    url: "/farmfriend/landCrop/save",
    data: options
  });
};

// 作物种类
export const CropList = function (name) {
  return server({
    url: `http://110.87.103.59:18086/farmfriend/crop/getCropList?name=${name}`
  })
}
